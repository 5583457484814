export enum MediaTypeEnum {
  IMAGE = 'image',
  VIDEO = 'video',
  MODEL = 'model',
  AR_MODEL = 'ar-model',
  EPUB = 'epub',
}

export type SortableItem = {
  sortIndex?: number;
  createdAt: Date;
};

export const mediaType2ds = [MediaTypeEnum.IMAGE, MediaTypeEnum.VIDEO];

export const mediaType3ds = [MediaTypeEnum.MODEL];
export const mediaTypeEpub = [MediaTypeEnum.EPUB];

export type MediaType =
  | MediaTypeEnum.IMAGE
  | MediaTypeEnum.VIDEO
  | MediaTypeEnum.MODEL
  | MediaTypeEnum.AR_MODEL
  | MediaTypeEnum.EPUB;

export const getMediaType = (mimeType?: string): MediaType | undefined =>
  mimeType?.startsWith('video/')
    ? MediaTypeEnum.VIDEO
    : mimeType?.startsWith('image/')
    ? MediaTypeEnum.IMAGE
    : mimeType?.startsWith('model/')
    ? mimeType?.endsWith('vnd.usdz+zip')
      ? MediaTypeEnum.AR_MODEL
      : MediaTypeEnum.MODEL
    : mimeType?.endsWith('epub+zip')
    ? MediaTypeEnum.EPUB
    : undefined;

export const isModelAsset = (mimeType?: string): boolean => {
  return getMediaType(mimeType) === MediaTypeEnum.MODEL;
};

export const isARModelAsset = (mimeType?: string): boolean => {
  return getMediaType(mimeType) === MediaTypeEnum.AR_MODEL;
};

export const isVideoAsset = (mimeType?: string): boolean => {
  return getMediaType(mimeType) === MediaTypeEnum.VIDEO;
};

export const isImageAsset = (mimeType?: string): boolean => {
  return getMediaType(mimeType) === MediaTypeEnum.IMAGE;
};

export const isEPUBAsset = (mimeType?: string): boolean => {
  return getMediaType(mimeType) === MediaTypeEnum.EPUB;
};

export const dateFilter = (a: SortableItem, b: SortableItem) => {
  if (a.sortIndex && b.sortIndex && b.sortIndex !== a.sortIndex) {
    return b.sortIndex - a.sortIndex;
  }

  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
};
